import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from '../../../utils/axios';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
// eslint-disable-next-line
  const [otp, setOtp] = useState('');
const [showOtpInput, setShowOtpInput] = useState(false);

  const LoginSchema = Yup.object().shape({
    number: Yup.number().required('Enter Valid Mobile Number').required('Number is required'),
    password: Yup.string().required('Password is required'),
    otp: Yup.number().typeError('OTP must be a number')
  });

  const defaultValues = {
    number: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    // reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  // const onSubmit = async (data) => {
  //   try {
  //     await login(data.number, data.password);
  //   } catch (error) {
  //     console.error(error);
  //     reset();
  //     if (isMountedRef.current) {
  //       setError('afterSubmit', error);
  //     }
  //   }
  // };

  const onSubmit = async (data) => {
    if (showOtpInput) {
      // Verify OTP
      try {
        await login(data.number, data.password, data.otp);
      } catch (error) {
        console.error(error);
        // reset();
        if (isMountedRef.current) {
          setError('afterSubmit', error);
        }
      }
    } else {
    try {
      const response = await axios.post('/api/userauth/v1/admin/', { mobile: data.number, password: data.password });
      if (response.data.success) {
        setShowOtpInput(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      // reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  }
  };
  


  // const onSubmit = async (data) => {
  //   try {
  //     const response = await login(data.number, data.password);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     reset();
  //     if (isMountedRef.current) {
  //       setError('afterSubmit', { message: 'There was an error connecting to the server. Please try again later.' });
  //     }
  //   }
  // };
  

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="number" label="Phone Number" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showOtpInput && <RHFTextField name="otp" label="OTP" />}

      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
