// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle   src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  // blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  users: getIcon('ic_user'),
  extra: getIcon('ic_extra'),
  master: getIcon('ic_manage'),
  // sellerProfile: getIcon('ic_sellerProfile'),
  // buyerProfile: getIcon('ic_buyerProfile'),
  ecommerce: getIcon('ic_ecommerce'), 
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItems: getIcon('ic_menuItems'),
  products: getIcon('ic_products'),
  orderListing: getIcon('ic_orderListing'),
  keyFeature: getIcon('ic_keyFeature'),
  subscriptionPlanListing: getIcon('ic_subscriptionPlanListing'),
  sellerListing: getIcon('ic_sellerListing'),
  productReport: getIcon('ic_productReport'),
  buyerListing: getIcon('ic_buyerListing2'),
  inspectorListing: getIcon('ic_inspectorListing'),
  chaListing: getIcon('ic_cha'),
  containerCompany: getIcon('ic_cha'),
  // foodItemOne: getIcon('ic_pizza'),
  // booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard Menu',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'menuItems', path: PATH_DASHBOARD.general.menuItems, icon: ICONS.menuItems },
      // { title: 'products', path: PATH_DASHBOARD.general.products, icon: ICONS.products },
      // { title: 'order Listing', path: PATH_DASHBOARD.general.orderListing, icon: ICONS.orderListing },
      // { title: 'Product Report', path: PATH_DASHBOARD.general.productReport, icon: ICONS.productReport },
      // { title: 'key Data', path: PATH_DASHBOARD.general.keyFeature, icon: ICONS.keyFeature },
      // { title: 'Subscription Plan', path: PATH_DASHBOARD.general.subscriptionPlanListing, icon: ICONS.subscriptionPlanListing },
      // { title: 'Seller Listing', path: PATH_DASHBOARD.general.sellerListing, icon: ICONS.sellerListing },
      // { title: 'Buyer Listing', path: PATH_DASHBOARD.general.buyerListing, icon: ICONS.buyerListing },
      // { title: 'Inspector Listing', path: PATH_DASHBOARD.general.inspectorListing, icon: ICONS.inspectorListing },
      // { title: 'C H A Listing', path: PATH_DASHBOARD.general.chaListing, icon: ICONS.chaListing },
      // { title: 'Shipment Company', path: PATH_DASHBOARD.general.containerCompany, icon: ICONS.containerCompany },
      {
        title: 'users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'Seller Listing', path: PATH_DASHBOARD.users.sellerListing, },
          { title: 'Buyer Listing', path: PATH_DASHBOARD.users.buyerListing,  },
          { title: 'Inspector Listing', path: PATH_DASHBOARD.users.inspectorListing, },
          // { title: 'C H A Listing', path: PATH_DASHBOARD.users.chaListing, },
          // { title: 'Freight', path: PATH_DASHBOARD.users.cifListing, },
         
          
        ],
      },
      {
        title: 'Product',
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.products,
        children: [
          { title: 'Products', path: PATH_DASHBOARD.products.list, },
          { title: 'order Listing', path: PATH_DASHBOARD.general.orderListing, },
          { title: 'Custom Quote Listing', path: PATH_DASHBOARD.products.customQuote, },
          { title: 'Product Report', path: PATH_DASHBOARD.general.productReport, },
          { title: 'product Report Keys', path: PATH_DASHBOARD.products.productReportKeys, },
          // { title: 'Report Parameter', path: PATH_DASHBOARD.products.reportParameter, },
          { title: 'Product Report Values', path: PATH_DASHBOARD.products.productReportValues, },
         
        ],
      },
      {
        title: 'Extras',
        path: PATH_DASHBOARD.extra.root,
        icon: ICONS.extra,
        children: [
          { title: 'key Data', path: PATH_DASHBOARD.extra.keyFeature, },
          { title: 'Subscription Plan', path: PATH_DASHBOARD.extra.subscriptionPlanListing, },
          { title: 'Coupon', path: PATH_DASHBOARD.extra.Coupon, },
          { title: 'Container Company', path: PATH_DASHBOARD.extra.containerCompany, },
          { title: 'Countries Listing', path: PATH_DASHBOARD.extra.countriesListing, },
          { title: 'News Listing', path: PATH_DASHBOARD.extra.newsListing, },
          { title: 'C H A Listing', path: PATH_DASHBOARD.extra.chaListing, },
          { title: 'Freight', path: PATH_DASHBOARD.extra.cifListing, },
          
        ],
      },
      {
        title: 'masters',
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.master,
        children: [
          { title: 'Product Category', path: PATH_DASHBOARD.master.productCategory},
          { title: 'Packaging Material', path: PATH_DASHBOARD.master.packagingMaterial},
          // { title: 'Product Images', path: PATH_DASHBOARD.master.productImages},
          { title: 'Product Size', path: PATH_DASHBOARD.master.productSize},
          { title: 'Prod pkg size', path: PATH_DASHBOARD.master.prodPkgSize},
          { title: 'Product Variety', path: PATH_DASHBOARD.master.productVariety, },
          { title: 'Market locations', path: PATH_DASHBOARD.master.marketLocations},
          { title: 'Ports', path: PATH_DASHBOARD.master.Ports},
          
        ],
      },
      // { title: 'seller Profile', path: PATH_DASHBOARD.sellerProfile.list, icon: ICONS.sellerProfile },
      // { title: 'buyer Profile', path: PATH_DASHBOARD.buyerProfile.list, icon: ICONS.buyerProfile },

    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
      // MANAGEMENT : USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },
      // {
      //   title: 'Food Items',
      //   path: PATH_DASHBOARD.foodItems.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.foodItems.shop },
      //     { title: 'product', path: PATH_DASHBOARD.foodItems.productById },
      //     { title: 'list', path: PATH_DASHBOARD.foodItems.list },
      //     { title: 'create', path: PATH_DASHBOARD.foodItems.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.foodItems.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.foodItems.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.foodItems.invoice },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },
];

export default navConfig;
