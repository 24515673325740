import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

export default function RHFDatePicker({ name, label, ...rest }) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <DatePicker
          label={label}
          renderInput={(props) => <TextField {...props} />}
          value={field.value}
          onChange={(date) => field.onChange(date)}
          {...rest}
        />
      )}
    />
  );
}
// Add prop type validations
RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};