import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';













// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'menuItems', element: <MenuItems /> },
        { path: 'products', element: <Products /> },
        // { path: 'master', element: <PackagingMaterial /> },
        { path: 'sellerProfile', element: <SellerProfile /> },
        { path: 'inspectorProfile', element: <InspectorProfile /> },
        // { path: 'sellerListing', element: <SellerListing /> },
        // { path: 'chaListing', element: <CHAListing /> },
        // { path: 'containerCompany', element: <ContainerCompanyListing /> },
        { path: 'orderListing', element: <OrderListing /> },
        // { path: 'buyerListing', element: <BuyerListing /> },
        { path: 'buyerProfile', element: <BuyerProfile /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'keyFeature', element: <KeyFeature /> },
        // { path: 'inspectorListing', element: <InspectorListing /> },
        // { path: 'subscriptionPlanListing', element: <SubscriptionPlanListing /> },
        { path: 'productReport', element: <ProductReportListing /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'menuItems',
          children: [
            // { element: <Navigate to="/dashboard/menuItems" replace />, index: true },
            { path: 'menuItems', element: <MenuItems /> },
            { path: 'sub_items/new', element: <AddSubMenuItem /> },
            { path: 'sub_items/:name/edit', element: <AddSubMenuItem /> },
            
          ],
        },
        
        {
          path: 'extra',
          children: [
            { path: 'keyFeature', element: <KeyFeature /> },
            { path: 'subscriptionPlanListing', element: <SubscriptionPlanListing /> },
            { path: 'coupon', element: <Coupon /> },
            { path: 'containerCompany', element: <ContainerCompanyListing /> },
            { path: 'countriesListing', element: <CountriesListing /> },
            { path: 'newsListing', element: <NewsListing /> },
            { path: 'chaListing', element: <CHAListing /> },
            { path: 'cifListing', element: <CIFListing /> },
            
          ],
        },
        {
          path: 'users',
          children: [
            { path: 'sellerListing', element: <SellerListing /> },
            { path: 'buyerListing', element: <BuyerListing /> },
            { path: 'inspectorListing', element: <InspectorListing /> },
            // { path: 'chaListing', element: <CHAListing /> },
            // { path: 'cifListing', element: <CIFListing /> },
            
          ],
        },
        {
          path: 'products',
          children: [
            
            { path: 'list', element: <Products /> },
            // { path: 'sub_items/new', element: <AddSubMenuItem /> },
            { path: 'productModify/:name/edit', element: <ProductModify /> },
            { path: 'productReportKeys', element: <ProductReportKeys /> },
            // { path: 'reportParameter', element: <ReportParameter /> },
            { path: 'customQuote', element: <CustomQuote /> },
            { path: 'productReportValues', element: <ProductReportValues /> },
           
          ],
        },
        {
          path: 'sellerProfile',
          children: [
            { element: <Navigate to="/dashboard/sellerProfile/list" replace />, index: true },
            { path: 'list/:name/edit', element: <SellerProfile /> },
            { path: 'sellOrderDetails', element: <SellOrderDetails /> },
          ],
        },
        {
          path: 'InspectorProfile',
          children: [
            { element: <Navigate to="/dashboard/InspectorProfile/list" replace />, index: true },
            { path: 'list/:name/edit', element: <InspectorProfile /> },
            { path: 'sellOrderDetails', element: <SellOrderDetails /> },
          ],
        },
        {
          path: 'buyerProfile',
          children: [
            { element: <Navigate to="/dashboard/buyerProfile/list" replace />, index: true },
            { path: 'list/:name/edit', element: <BuyerProfile /> },
            // { path: 'sub_items/new', element: <AddSubMenuItem /> },
          ],
        },
        {
          path: 'productReport',
          children: [
            { element: <Navigate to="/dashboard/productReport/list" replace />, index: true },
            { path: 'list', element: <ProductReportListing /> },
            { path: 'productReportDetails', element: <ProductReportDetails /> },
            // { path: 'sub_items/new', element: <AddSubMenuItem /> },
          ],
        },
        {
          path: 'orderListing',
          children: [
            { element: <Navigate to="/dashboard/orderListing/list" replace />, index: true },
            { path: 'list', element: <OrderListing /> },
            { path: 'invoice', element: <OrderInvoice /> },
          ],
        },
        {
          path: 'master',
          children: [
            { element: <Navigate to="/dashboard/master/packagingMaterial" replace />, index: true },
            { path: 'packagingMaterial', element: <PackagingMaterial/> },
            { path: 'productSize', element: <ProductSize/> },
            { path: 'prodPkgSize', element: <ProdPkgSize/> },
            { path: 'productVariety', element: <ProductVariety /> },
            { path: 'marketLocations', element: <MarketLocations/> },
            { path: 'productCategory', element: <ProductCategory/> },
            // { path: 'productImages', element: <ProductImages/> },
            { path: 'Ports', element: <Ports/> },
            // { path: 'sub_items/new', element: <AddSubMenuItem /> },
            
          ],
        },
        
        
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        
        
      ],
    },
    
    
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: <Navigate to="/auth/login" replace />,
          index: true
        },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const MenuItems = Loadable(lazy(() => import('../pages/dashboard/MenuItems')));
const Products = Loadable(lazy(() => import('../pages/dashboard/Products')));
const AddSubMenuItem = Loadable(lazy(() => import('../sections/@dashboard/menuItems/menuItem-list/SubMenuDummyData/AddSubMenuItem')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));

const ProductModify = Loadable(lazy(() => import( '../sections/@dashboard/productsListing/productMore/ProductModify')));
const PackagingMaterial = Loadable(lazy(() => import ( '../pages/dashboard/PackagingMaterial')));
const ProductSize  = Loadable(lazy(() => import ( '../pages/dashboard/ProductSize')));
const ProdPkgSize = Loadable(lazy(() => import ( '../pages/dashboard/ProdPkgSize')));

const ProductReportKeys = Loadable(lazy(() => import ( '../pages/dashboard/ProductReportKeys')));
// const ReportParameter = Loadable(lazy(() => import ( '../pages/dashboard/ReportParameter')));
const MarketLocations = Loadable(lazy(() => import ( '../pages/dashboard/MarketLocations')));
const Ports = Loadable(lazy(() => import ( '../pages/dashboard/Ports')));
const ProductCategory = Loadable(lazy(() => import ( '../pages/dashboard/ProductCategory')));
// const ProductImages = Loadable(lazy(() => import ( '../pages/dashboard/ProductImages')));
// OrderListing
const OrderListing = Loadable(lazy(() => import ( '../pages/dashboard/OrderListing')));
const OrderInvoice  = Loadable(lazy(() => import ( '../sections/@dashboard/OrderListing/OrderInvoice.js/OrderInvoice')));

const CustomQuote = Loadable(lazy(() => import ( '../pages/dashboard/CustomQuote')));

// InspectorListing
const InspectorListing = Loadable(lazy(() => import ( '../pages/dashboard/InspectorListing')));
const InspectorProfile  = Loadable(lazy(() => import ( '../pages/dashboard/InspectorProfile')));


// Seller Profile
const SellerProfile = Loadable(lazy(() => import ( '../pages/dashboard/SellerProfile')));
const SellOrderDetails = Loadable(lazy(() => import ( '../sections/@dashboard/SellerProfile/ViewSellOrder/OrderDetails/SellOrderDetails')));
const SellerListing = Loadable(lazy(() => import ('../pages/dashboard/SellerListing')));

// Buyer Profile 
const BuyerListing = Loadable(lazy(() => import ('../pages/dashboard/BuyerListing')));
const BuyerProfile = Loadable(lazy(() => import ( '../pages/dashboard/BuyerProfile')));

// Extras
// KeyFeatures
const KeyFeature = Loadable(lazy(() => import ( '../pages/dashboard/KeyFeature')));
const CountriesListing = Loadable(lazy(() => import ( '../pages/dashboard/CountriesListing')));
const NewsListing  = Loadable(lazy(() => import ( '../pages/dashboard/NewsListing')));

// ProductReportListing
const ProductReportListing= Loadable(lazy(() => import ( '../pages/dashboard/ProductReportListing')));
const ProductVariety= Loadable(lazy(() => import ( '../pages/dashboard/ProductVariety')));
const ProductReportValues = Loadable(lazy(() => import ( '../pages/dashboard/ProductReportValues')));
const ProductReportDetails = Loadable(lazy(() => import ( '../pages/dashboard/ProductReportDetails')));

// SubscriptionPlanListing
const SubscriptionPlanListing  = Loadable(lazy(() => import ( '../pages/dashboard/SubscriptionPlanListing')));

// CHAListing
const CHAListing = Loadable(lazy(() => import ( '../pages/dashboard/CHAListing')))

// CIFListing
const CIFListing = Loadable(lazy(() => import ( '../pages/dashboard/CIFListing')))

// ContainerCompanyListing
const ContainerCompanyListing = Loadable(lazy(() => import ( '../pages/dashboard/ContainerCompanyListing')));


// Coupon
const Coupon = Loadable(lazy(() => import ( '../pages/dashboard/Coupon')));

// Chat
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));


const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
