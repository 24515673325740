const pageTitleMap = {
    '/dashboard/app': 'Dashboard',
    '/dashboard/products/list': 'Product Listing',
    '/dashboard/products/productReportKeys': 'Product Report Keys',
    '/dashboard/products/reportParameter': 'Report Parameter',
    '/dashboard/products/customQuote': 'Custom Quote Listing',
    '/dashboard/products/productReportValues': 'Product Report Values',
    '/dashboard/extra/keyFeature': 'Key Data Listing',
    '/dashboard/extra/Coupon': 'Coupon Listing',
    '/dashboard/extra/containerCompany': 'Container Company Listing',
    '/dashboard/extra/subscriptionPlanListing': 'Subscription Plans',
    '/dashboard/extra/countriesListing': 'Countries Listing',
    '/dashboard/extra/newsListing': 'News Listing',
    '/dashboard/users/sellerListing': 'Seller Listing',
    '/dashboard/users/buyerListing': 'Buyer Listing',
    '/dashboard/users/inspectorListing': 'Inspector Listing',
    '/dashboard/extra/chaListing': 'C H A Listing',
    '/dashboard/extra/cifListing': 'Freight',
    '/dashboard/sellerProfile/list': 'Seller Profile',
    '/dashboard/InspectorProfile/list': 'Inspector Profile',
    '/dashboard/buyerProfile/list': 'Buyer Profile',
    '/dashboard/productReport/list': 'Product Report',
    '/dashboard/orderListing/list': 'Order Listing',
    '/dashboard/master/packagingMaterial': 'Packaging Material',
    '/dashboard/master/productSize': 'Product Size',
    '/dashboard/master/prodPkgSize': 'Product Package Size',
    '/dashboard/master/productVariety': 'ProductVariety',
    '/dashboard/master/marketLocations': 'Market Locations',
    '/dashboard/master/productCategory': 'Product Category',
    '/dashboard/master/productImages': 'Product Images',
    '/dashboard/master/Ports': 'Ports Listing',
    '/dashboard/chat': 'Chat',


  };
  export default pageTitleMap;