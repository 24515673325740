// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    menuItems: path(ROOTS_DASHBOARD, '/menuItems'),
    master: path(ROOTS_DASHBOARD, '/master'),
    orderListing: path(ROOTS_DASHBOARD, '/orderListing'),
    // containerCompany: path(ROOTS_DASHBOARD, '/containerCompany'),
    // chaListing: path(ROOTS_DASHBOARD, '/chaListing'),
    sellerProfile: path(ROOTS_DASHBOARD, '/sellerProfile'),
    inspectorProfile: path(ROOTS_DASHBOARD, '/inspectorProfile'),
    // sellerListing: path(ROOTS_DASHBOARD, '/sellerListing'),
    buyerProfile: path(ROOTS_DASHBOARD, '/buyerProfile'),
    // keyFeature: path(ROOTS_DASHBOARD, '/keyFeature'),
    // buyerListing: path(ROOTS_DASHBOARD, '/buyerListing'),
    // inspectorListing: path(ROOTS_DASHBOARD, '/inspectorListing'),
    // subscriptionPlanListing: path(ROOTS_DASHBOARD, '/subscriptionPlanListing'),
    productReport: path(ROOTS_DASHBOARD, '/productReport'),

    // booking: path(ROOTS_DASHBOARD, '/booking')
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  menuItems: {
    root: path(ROOTS_DASHBOARD, '/menuItems'),
    list: path(ROOTS_DASHBOARD, '/menuItems'),
    sub_items: path(ROOTS_DASHBOARD, '/menuItems/sub_items/:name'),
  },
  extra: {
    root: path(ROOTS_DASHBOARD, '/extra'),
    keyFeature: path(ROOTS_DASHBOARD, '/extra/keyFeature'),
    subscriptionPlanListing: path(ROOTS_DASHBOARD, '/extra/subscriptionPlanListing'),
    Coupon: path(ROOTS_DASHBOARD, '/extra/Coupon'),
    containerCompany: path(ROOTS_DASHBOARD, '/extra/containerCompany'),
    countriesListing: path(ROOTS_DASHBOARD, '/extra/countriesListing'),
    newsListing: path(ROOTS_DASHBOARD, '/extra/newsListing'),
    chaListing: path(ROOTS_DASHBOARD, '/extra/chaListing'),
    cifListing: path(ROOTS_DASHBOARD, '/extra/cifListing'),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    list: path(ROOTS_DASHBOARD, '/products/list'),
    productModify: path(ROOTS_DASHBOARD, '/products/productModify/:name'),
    productReportKeys: path(ROOTS_DASHBOARD, '/products/productReportKeys'),
    // reportParameter: path(ROOTS_DASHBOARD, '/products/reportParameter'),
    // productVariety: path(ROOTS_DASHBOARD, '/products/productVariety'),
    productReportValues: path(ROOTS_DASHBOARD, '/products/productReportValues'),
    customQuote: path(ROOTS_DASHBOARD, '/products/customQuote'),
    // sub_items: path(ROOTS_DASHBOARD, '/menuItems/sub_items/:name'),
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    sellerListing: path(ROOTS_DASHBOARD, '/users/sellerListing'),
    buyerListing: path(ROOTS_DASHBOARD, '/users/buyerListing'),
    inspectorListing: path(ROOTS_DASHBOARD, '/users/inspectorListing'),
    // chaListing: path(ROOTS_DASHBOARD, '/users/chaListing'),
    // cifListing: path(ROOTS_DASHBOARD, '/users/cifListing'),
    
    
  },
  master: {
    root: path(ROOTS_DASHBOARD, '/master/packagingMaterial'),
    packagingMaterial: path(ROOTS_DASHBOARD, '/master/packagingMaterial'),
    productSize: path(ROOTS_DASHBOARD, '/master/productSize'),
    prodPkgSize: path(ROOTS_DASHBOARD, '/master/prodPkgSize'),
    marketLocations: path(ROOTS_DASHBOARD, '/master/marketLocations'),
    Ports: path(ROOTS_DASHBOARD, '/master/Ports'),
    productCategory: path(ROOTS_DASHBOARD, '/master/productCategory'),
    // productImages: path(ROOTS_DASHBOARD, '/master/productImages'),
    productVariety: path(ROOTS_DASHBOARD, '/master/productVariety'),

  },
  sellerProfile: {
    root: path(ROOTS_DASHBOARD, '/sellerProfile'),
    list: path(ROOTS_DASHBOARD, '/sellerProfile/list'),
    // list: path(ROOTS_DASHBOARD, '/sellerProfile/list/:name/edit'),
    editById: path(ROOTS_DASHBOARD, '/sellerProfile/list/:name/edit'),
    sellOrderDetails: path(ROOTS_DASHBOARD, '/sellerProfile/sellOrderDetails/'),
  },
  inspectorProfile: {
    root: path(ROOTS_DASHBOARD, '/inspectorProfile'),
    list: path(ROOTS_DASHBOARD, '/inspectorProfile/list'),
    // list: path(ROOTS_DASHBOARD, '/inspectorProfile/list/:name/edit'),
    editById: path(ROOTS_DASHBOARD, '/inspectorProfile/list/:name/edit'),
    sellOrderDetails: path(ROOTS_DASHBOARD, '/inspectorProfile/sellOrderDetails/'),
  },
  buyerProfile: {
    root: path(ROOTS_DASHBOARD, '/buyerProfile'),
    list: path(ROOTS_DASHBOARD, '/buyerProfile/list'),
  },
  productReport: {
    root: path(ROOTS_DASHBOARD, '/productReport'),
    list: path(ROOTS_DASHBOARD, '/productReport/list'),
    productReportDetails: path(ROOTS_DASHBOARD, '/productReport/productReportDetails'),
  },
  orderListing: {
    root: path(ROOTS_DASHBOARD, '/orderListing'),
    list: path(ROOTS_DASHBOARD, '/orderListing/list'),
    invoice: path(ROOTS_DASHBOARD, '/orderListing/invoice'),
    // sub_items: path(ROOTS_DASHBOARD, '/menuItems/sub_items/:name'),
  },


  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },

  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  // }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
