
import PropTypes from 'prop-types';
// @mui
import { Box, Typography,} from '@mui/material';
// eslint-disable-next-line
import { useTheme, styled } from '@mui/material/styles';
//
// import Breadcrumbs from './Breadcrumbs';

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};
// eslint-disable-next-line 
export default function HeaderBreadcrumbs({ links, action, heading, moreLink = '' || [], sx, ...other }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    
    <Box sx={{ m: 0, ...sx,    }}>
      <Box sx={{ display: 'flex',  }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6"  sx={{color: !isLight ? 'white' : '#212b36',}} >
            {heading}
          </Typography>
          {/* <Breadcrumbs links={links} {...other} /> */}
        </Box>

        {/* {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>} */}
      </Box>
{/* 
      <Box sx={{ mt: 1 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box> */}
    </Box>
  );
}
