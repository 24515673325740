import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';


// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
     
      {/* 
<svg width="100%" height="100%" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
<defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
<g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
<path d="M183.947 91.8578C184.149 142.51 143.25 183.743 92.5972 183.954C41.9444 184.165 0.718867 143.274 0.517404 92.6213C0.31594 41.969 41.2149 0.736268 91.8677 0.525427C142.52 0.314585 183.746 41.2055 183.947 91.8578Z" fill="url(#BG3)" fillOpacity="0.20"/>
<path d="M84.2344 123.861L145.147 122.744L145.473 138.578C144.953 149.636 142.312 152.629 134.133 153.229L61.193 154.566C51.9707 155.022 49.239 152.063 49.2555 140.342L47.4955 55.117C47.2715 43.8099 44.2038 30.688 56.8279 30.8234L131.717 29.4502C144.814 29.2101 143.501 39.4259 143.848 59.8182L91.2307 60.783C72.644 60.7595 73.3284 64.3896 73.6491 81.1397L74.285 111.93C74.711 120.812 76.8932 123.478 84.2344 123.861Z" fill="url(#BG1)"/>


</g>

</svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 165 100" fill="none">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        {/* <g clip-path="url(#clip0_201_2)"> */}
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path d="M157.977 33.1493C161.865 37.008 161.865 43.3514 157.977 47.2393L115.678 89.5093C111.819 93.3972 105.476 93.3972 101.588 89.5093C97.7292 85.6507 97.7292 79.3072 101.588 75.4193L143.887 33.1493C147.775 29.2614 154.089 29.2614 157.977 33.1493Z" fill="url(#BG1)" />
          <path d="M2.92863 33.1491C6.81653 29.2612 13.1307 29.2612 17.0186 33.1491L52.2729 68.3742L115.707 4.9399C119.566 1.08123 125.909 1.08123 129.797 4.9399C133.656 8.82781 133.656 15.1712 129.797 19.0299L59.3179 89.5091C55.43 93.3971 49.0865 93.3971 45.2279 89.5091H45.1986L2.92863 47.2391C-0.959282 43.3512 -0.959282 37.0078 2.92863 33.1491Z" fill="url(#BG2)" fillOpacity="3.20" />
          <path d="M31.1084 2.89401C34.9963 -0.96467 41.3397 -0.96467 45.1984 2.89401L59.3176 16.984C63.1763 20.8719 63.1763 27.2153 59.3176 31.1033C55.4297 34.9619 49.0863 34.9619 45.2276 31.1033L31.1084 16.984C27.2497 13.1253 27.2497 6.78191 31.1084 2.89401Z" fill="url(#BG1)" />
        </g>
        <defs>
          <clipPath id="clip0_201_2">
            <rect width="161" height="161" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
